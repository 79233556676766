<template>
  <CCard
    v-if="item"
    class="candidate-card border-primary"
    style="min-height: 375px"
  >
    <div class="d-flex">
      <CandidateInfo :item="candidateInfoData" />
    </div>
    <CCardBody class="content-block">
      <div class="d-flex flex-column justify-content-between">
        <CRow>
          <CCol class="lable" col="5">{{
            isNurse ? `Main Speciality` : `Speciality`
          }}</CCol>
          <CCol
            v-if="isWrapped(speciality)"
            class="value text-truncate"
            col="7"
            v-c-tooltip="{
              content: speciality,
            }"
          >
            {{ speciality || "--" }}
          </CCol>
          <CCol v-else class="value text-truncate" col="7">
            {{ speciality || "--" }}
          </CCol>
        </CRow>

        <CRow v-if="!isNurse && !isDentist">
          <CCol class="lable" col="5">
            {{ isAHP ? `Sub Type/License` : `Sub Speciality` }}</CCol
          >
          <CCol
            v-if="isWrapped(subSpeciality)"
            class="value text-truncate"
            col="7"
            v-c-tooltip="{
              content: subSpeciality,
            }"
          >
            {{ subSpeciality || "--" }}
          </CCol>
          <CCol v-else class="value text-truncate" col="7">
            {{ subSpeciality || "--" }}
          </CCol>
        </CRow>

        <CRow v-if="isNurse">
          <CCol class="lable" col="5"> Other Expertise </CCol>
          <CCol
            v-if="isWrapped(otherSpecialities)"
            class="value text-truncate"
            col="7"
            v-c-tooltip="{
              content: otherSpecialities,
            }"
          >
            {{ otherSpecialities || "--" }}
          </CCol>
          <CCol v-else class="value text-truncate" col="7">
            {{ otherSpecialities || "--" }}
          </CCol>
        </CRow>

        <CRow>
          <CCol class="lable" col="5"> Qualification </CCol>
          <CCol
            v-if="isWrapped(qualificationName)"
            class="value text-truncate"
            col="7"
            v-c-tooltip="{
              content: qualificationName,
            }"
          >
            {{ qualificationName || "--" }}
          </CCol>
          <CCol v-else class="value text-truncate" col="7">
            {{ qualificationName || "--" }}
          </CCol>
        </CRow>

        <CRow>
          <CCol class="lable" col="5"> Country Obtained </CCol>
          <CCol class="value" col="7">
            {{ qualificationCountry || "--" }}
          </CCol>
        </CRow>

        <CRow>
          <CCol class="lable" col="5"> Date Obtained</CCol>
          <CCol class="value" col="7">
            {{ qualificationDate || "--" }}
          </CCol>
        </CRow>

        <CRow v-if="!isAHP">
          <CCol class="lable" col="5">Level</CCol>
          <CCol
            v-if="isWrapped(levelName)"
            class="value text-truncate"
            col="7"
            v-c-tooltip="{
              content: levelName,
            }"
          >
            {{ levelName || "--" }}
          </CCol>
          <CCol v-else class="value text-truncate" col="7">
            {{ levelName || "--" }}
          </CCol>
        </CRow>
        <CRow>
          <CCol class="lable" col="5">Local License</CCol>
          <CCol class="value" col="7">
            {{ localLicense || "N/A" }}
          </CCol>
        </CRow>
      </div>
    </CCardBody>
    <div class="d-flex justify-content-center pb-2">
      <CButton
        color="primary"
        variant="outline"
        class="mx-2 action-btn"
        @click="previewCV(cvDocument)"
        :disabled="!cvDocument"
      >
        CV/Resume</CButton
      >
      <CButton
        v-if="isCustomer"
        color="primary"
        variant="outline"
        class="mx-2 action-btn"
        @click="confirmationPopupwithReason(getShortliststatus.status)"
      >
        Shortlist</CButton
      >
      <CButton
        color="primary"
        variant="outline"
        class="mx-2 action-btn"
        @click="navigateToCandidate(item)"
      >
        More Details</CButton
      >
    </div>
    <PreviewModal
      v-if="previewModal.isPreview"
      :modalTitle="previewModal.modalTitle"
      :modalColor="previewModal.modalColor"
      :isShowPopup="previewModal.isShowPopup"
      :buttons="previewModal.buttons"
      :modalCallBack="modalCallBack"
      :isPreview="previewModal.isPreview"
      :iframe="iframe"
      :size="previewModal.size"
      :closeOnBackdrop="previewModal.closeOnBackdrop"
    />
    <Modal
      :modalTitle="noCVModal.modalTitle"
      :modalColor="noCVModal.modalColor"
      :modalContent="noCVModal.modalContent"
      :isShowPopup="noCVModal.isShowPopup"
      :buttons="noCVModal.buttons"
      :modalCallBack="noCVModalCallback"
      :closeOnBackdrop="false"
      :stepperModal="noCVModal.stepperModal"
    />
    <PopupModal
      v-if="popupModal.isShowPopup"
      :modalTitle="popupModal.modalTitle"
      :modalColor="popupModal.modalColor"
      :modalContent="popupModal.modalContent"
      :modalLabel="popupModal.modalLabel"
      :isShowPopup="popupModal.isShowPopup"
      :popupModalCallBack="popupModalCallBack"
      :candidateItem="popupModal.candidateItem"
      :modalJob="popupModal.modalJob"
      :modalReasonType="popupModal.modalTitle"
      :isCommentRequired="false"
    />
  </CCard>
</template>

<script>
import CandidateInfo from "@/containers/CandidateList/CandidateInfo";
import moment from "moment";
import { mapGetters, mapActions } from "vuex";
import { getScope, Role, appendAccessToken } from "@/helpers/helper";
import PreviewModal from "@/components/reusable/PreviewModal";
import pdf from "vue-pdf";
import Modal from "@/components/reusable/Modal";
import PopupModal from "@/components/reusable/PopupModal";
import { BASE_URL } from "@/service_urls";

export default {
  name: "CandidateList",
  data() {
    return {
      currentUserRole: getScope(),
      Role,
      previewModal: {
        modalColor: "primary",
        modalTitle: "Document Preview",
        isShowPopup: false,
        buttons: ["OK"],
        isPreview: false,
        size: "lg",
        closeOnBackdrop: false,
      },
      iframe: {
        src: "",
        loaded: false,
        isImg: false,
        pdf: {
          ispdf: false,
          src: "",
          numPages: undefined,
          addContentClasses: "",
        },
      },
      noCVModal: {
        modalColor: "primary",
        modalTitle: "Document Preview",
        modalContent: "There is No CV / Resume Available for this Candidate",
        isShowPopup: false,
        buttons: ["OK"],
        stepperModal: true,
      },
      popupModal: {
        modalColor: "",
        modalTitle: "",
        modalContent: "",
        modalLabel: "",
        isShowPopup: false,
        candidateItem: {},
        modalJob: "",
      },
      wrapMaxLength: 27,
    };
  },
  props: {
    item: {
      type: Object,
      default: {},
    },
  },
  components: {
    CandidateInfo,
    PreviewModal,
    Modal,
    PopupModal,
  },
  computed: {
    ...mapGetters([
      "getUserId",
      "getCustomerId",
      "getShortliststatus",
      "getInterviewstatus",
      "getReleasestatus",
      "getRecruitstatus",
      // "getFacilityConfig",
      "getOrgID",
      "currentPageSchema",
    ]),
    isCustomer() {
      return this.currentUserRole === this.Role.customerAdmin ||
        this.currentUserRole === this.Role.customerRecruiter
        ? true
        : false;
    },
    pqrLevels() {
      if (this.item.eligibility) {
        return this.item.eligibility ? this.item.eligibility : "--";
      }
      return this.item.level_name;
    },
    candidateInfoData() {
      let {
        first_name,
        surname,
        email,
        gender,
        speciality,
        sub_speciality,
        candidate_level,
        candidate_type,
        nationality,
        age,
        phone,
        eligibility_letter_available_for,
        local_license,
        candidate_uid,
        whatsapp,
        retired_clinician,
        volunteer,
        profile_picture,
        highest_qualification_name,
        highest_qualification_acronym,
        last_date_of_login,
      } = Object.assign(this.item, {
        candidate_type: this.item.candidate_type_name || "",
        nationality: this.item.nationality_name,
        main_qualification: this.item.main_qualification || "",
        gender: this.item.gender_name || "",
        candidate_uid: this.item.candidate_uid || null,
        whatsapp: this.item.whatsapp || "",
        local_license: this.item.local_license,
        retired_clinician: this.checkData(this.item.retired_clinician),
        volunteer: this.checkData(this.item.volunteer),
        highest_qualification_name:
          this.item.highest_qualification_name || null,
        highest_qualification_acronym:
          this.item.highest_qualification_acronym || null,
        last_date_of_login: this.item.last_date_of_login,
      });

      eligibility_letter_available_for = eligibility_letter_available_for
        ? eligibility_letter_available_for.split(",").join(", ")
        : "--";
      return {
        first_name,
        surname,
        gender,
        speciality,
        sub_speciality,
        candidate_level,
        candidate_type,
        nationality,
        email,
        age,
        phone,
        whatsapp,
        eligibility_letter_available_for,
        local_license,
        candidate_uid,
        retired_clinician,
        volunteer,
        profile_picture,
        highest_qualification_name,
        highest_qualification_acronym,
        last_date_of_login,
      };
    },
    specialisation() {
      let specialisation_data = null;
      this.item.qualifications.some((e) => {
        if (e.on_specialist_register) {
          specialisation_data = e;
          return true;
        }
        return false;
      });
      return specialisation_data;
    },
    splqualification_classification_name() {
      return (
        this.specialisation?.qualification?.qualification_classification_name ||
        null
      );
    },
    splqualification_country_name() {
      return this.specialisation?.country_name || null;
    },
    highestQualification() {
      let highestQualification = null;
      this.item.qualifications &&
        this.item.qualifications.length &&
        this.item.qualifications.some((e) => {
          if (e.main) {
            highestQualification = e;
            return true;
          }
          return false;
        });
      return highestQualification;
    },
    qualification() {
      return this.highestQualification?.qualification?.acronym || null;
    },
    qualificationCountry() {
      return this.item?.country_obtained || null;
    },
    qualificationDate() {
      if (this.item?.date_obtained != "null") {
        return this.item?.date_obtained;
      } else {
        return "--";
      }
    },
    cvDocument() {
      let _base_domain = `${window.location.origin}/api/v1`;
      if (this.item.cv_doc_uuid && this.item.cv_ext)
        return {
          link: appendAccessToken(
            `${_base_domain}/customer/${this.getCustomerId}/file${this.item.cv_doc_uuid}`
          ),
          document_ext: this.item.cv_ext,
        };
      return false;
    },
    isNurse() {
      return this.item?.candidate_type_id
        ? this.item?.candidate_type_id === 2
        : false;
    },
    isAHP() {
      return this.item?.candidate_type_id
        ? this.item?.candidate_type_id === 3
        : false;
    },
    isDentist() {
      return this.item?.candidate_type_id
        ? this.item?.candidate_type_id === 4
        : false;
    },
    localLicense() {
      return this.item?.eligibility_letter_available_for !== null
        ? this.item?.eligibility_letter_available_for.split(",").join(", ")
        : "N/A";
    },
    otherSpecialities() {
      return (
        _(this.item?.candidate_specialities)
          .filter((speciality) => {
            return (
              speciality.main != true &&
              !speciality.other_special_interest &&
              !speciality.special_interest
            );
          })
          .map((speciality) => {
            return speciality.speciality
              ? speciality.speciality?.speciality
              : speciality?.other_speciality;
          })
          .value()
          .join(", ") || "--"
      );
    },
    expirydays() {
      return this.item.doh_candidate_status.days_to_expire || -1;
    },
    qualificationName() {
      return this.item?.highest_qualification_acronym
        ? this.item?.highest_qualification_acronym
        : this.item?.highest_qualification_name;
    },
    speciality() {
      if (this.isNurse) return this.item?.main_speciality;
      return this.item?.speciality_name;
    },
    subSpeciality() {
      if (this.isNurse) return this.item?.other_expertise;
      if (this.isAHP) return this.item?.sub_type_license;
      return this.item?.sub_speciality_name;
    },
    otherSpecialities() {
      return this.item?.other_expertise;
    },
    levelName() {
      return this.item?.level_name;
    },
  },
  methods: {
    ...mapActions([
      "setCandidate",
      "setSelectedcandidateById",
      "setCandidateListBackUrl",
      "createshortlist",
      "releaseCandidate",
      "resetCandidateDetail",
      "createInterviewlist",
      "createRecruitList",
      "fetchAllJobsByShortlistProcess",
      "showToast",
    ]),
    isWrapped(data) {
      return data?.length > this.wrapMaxLength || false;
    },
    navigateToCandidate(item) {
      this.setCandidateListBackUrl(this.$route.fullPath);
      this.resetCandidateDetail();
      this.$router.push({ path: `/candidate/${item.candidate_uuid}` });
    },
    confirmationPopupwithReason(type) {
      this.popupModal.modalLabel = "";
      if (type === this.getShortliststatus.status) {
        this.popupModal.modalContent =
          "Please confirm would you like to shortlist this candidate for review/interview ?";
        this.popupModal.modalJob = "Select Job";
        this.fetchAllJobsByShortlistProcess(
          this.candidateInfoData?.candidate_uid
        );
      }
      this.popupModal.modalColor = "primary";
      this.popupModal.modalTitle = type;
      this.popupModal.isShowPopup = true;
      this.popupModal.candidateItem = {
        candidate_id: this.item.candidate_uid,
        doh_candidate_status: this.item.doh_candidate_status
          ? this.item.doh_candidate_status
          : null,
        shortlistStatus: this.getShortliststatus,
        interviewStatus: this.getInterviewstatus,
        recruitStatus: this.getRecruitstatus,
        releaseStatus: this.getReleasestatus,
      };
    },
    checkData(value) {
      if (value !== null) {
        return value === "Yes" || value === true ? "Yes" : "No";
      }
      return "--";
    },
    navigateToJobList({ job_id }) {
      this.$router.push({ path: `/jobs-list/?job_id=${job_id}` });
    },
    getDtSpl(qualification) {
      if (qualification != null) {
        let month_num = qualification.specialist_registration_month || null;
        let month_str = null;
        if (month_num) month_str = moment.monthsShort()[month_num - 1];
        return `${month_str} ${qualification.specialist_registration_year}`;
      }
      return null;
    },
    previewCV(data) {
      if (data) {
        this.preview(data);
      } else {
        this.noCVModal.isShowPopup = true;
      }
    },
    preview(data) {
      if (data?.link) this.iframe.url = data.link;
      else this.iframe.url = null;
      this.iframe.document_ext = data.document_ext.toLowerCase();
      if (["PNG", "JPG", "JPEG", "GIF"].includes(data.document_ext)) {
        this.iframe.loaded = false;
        this.iframe.ispdf = false;
        this.iframe.isImg = true;
        this.iframe.src = data.link;
        this.previewModal.isPreview = true;
        this.previewModal.isShowPopup = true;
      } else if (data.document_ext === "PDF") {
        this.iframe.isImg = false;
        this.iframe.loaded = false;
        this.iframe.pdf.ispdf = true;
        this.iframe.pdf.src = pdf.createLoadingTask(data.link);
        setTimeout(() => {
          this.iframe.pdf.src.promise.then((pdf) => {
            this.iframe.pdf.numPages = pdf.numPages;
          });
        }, 100);
        this.previewModal.isPreview = true;
        this.previewModal.isShowPopup = true;
      } else if (
        ["TXT", "EPS", "TIF", "TIFF", "RAW"].includes(data.document_ext)
      ) {
        this.showToast({
          class: "bg-danger text-white",
          message: "The selected file type is not available for Preview.",
        });
        this.iframe.src = "";
        this.iframe.loaded = false;
        this.iframe.isImg = false;
        this.iframe.pdf.ispdf = false;
        this.previewModal.isPreview = false;
        this.previewModal.isShowPopup = false;
      } else {
        this.iframe.isImg = false;
        this.iframe.ispdf = false;
        this.iframe.loaded = true;
        this.iframe.src =
          "https://view.officeapps.live.com/op/embed.aspx?src=" +
          data.link +
          "";
        this.previewModal.isPreview = true;
        this.previewModal.isShowPopup = true;
      }
    },
    noCVModalCallback(action) {
      this.noCVModal.isShowPopup = false;
    },
    modalCallBack(action) {
      this.previewModal.isPreview = false;
      this.previewModal.isShowPopup = false;
      this.iframe.src = "";
      this.iframe.loaded = false;
      this.iframe.isImg = false;
      this.iframe.pdf.ispdf = false;
    },
    popupModalCallBack(action, payload, candidateItem) {
      if (action === "Confirm") {
        let shortlistPayload = {
          candidate_id: candidateItem.candidate_id,
          reason_id: payload.reason_id || null,
          comments: payload.comments || "",
        };
        const { modalTitle } = this.popupModal;
        const {
          shortlistStatus: { status: shortlistStatus },
        } = candidateItem;

        if (modalTitle === shortlistStatus) {
          shortlistPayload.job_id = payload.job_id;
          this.createshortlist(shortlistPayload);
        }
        this.popupModal.isShowPopup = false;
      } else if (action === "Cancel" || action === false) {
        this.popupModal.isShowPopup = false;
      }
    },
  },
  filters: {
    moment: function (date) {
      return moment(date).format("MMM YYYY");
    },
  },
};
</script>

<style lang="scss" scoped>
.btnmargin {
  margin-right: 15px;
  padding-top: 0.1rem;
  padding-right: 0.5rem;
  padding-bottom: 0.05rem;
  padding-left: 0.5rem;
  font-size: 12px;
}
.btncol {
  text-align: center;
  vertical-align: middle;
}
.lblspan {
  font-weight: bold;
}
.flex-1 {
  font-size: 13.5px;
}
.flex-2 {
  font-size: 13.5px;
}
.add-details-btn {
  max-width: 200px;
}
.icon-wrapper {
  .icon {
    width: 50px;
    img {
      width: 35px;
      padding: 5px;
      text-align: center;
      cursor: pointer;
    }
    img.disabled {
      // pointer-events: none;
      cursor: not-allowed;
      opacity: 0.5 !important;
    }
  }
}
.border-top-primary {
  border-top: 1px solid #d8dbe0 !important;
  border-color: #dd3651 !important;
}
.left-side-width {
  width: 40%;
}
.right-side-width {
  width: 70%;
}
.action-btn {
  width: 100%;
  font-size: 11px;
}
.btn:disabled {
  cursor: not-allowed;
}
</style>
