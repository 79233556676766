<template>
  <div class="pl-2">
    <CModal
      :title="modalTitle"
      :color="modalColor"
      :show.sync="isShowModal"
      :closeOnBackdrop="false"
      :size="size"
    >
      <template #header>
        <h5>{{ modalTitle }}</h5>
        <CButtonClose @click="popupModalCallBack(false)" />
      </template>

      <template #footer>
        <CButton
          form="reasons"
          :color="modalColor"
          variant="outline"
          @click="popupModalCallBack('Cancel', payload)"
          >Cancel</CButton
        >
        <CButton form="reasons" :color="modalColor" type="Submit"
          >Confirm</CButton
        >
      </template>

      <div>
        <ValidationObserver ref="reasons" v-slot="{ handleSubmit }">
          <form id="reasons" @submit.prevent="handleSubmit(onSubmit)">
            <CRow class="row content-center">
              {{ modalContent }}
            </CRow>
            <div v-if="modalJob">
              <CRow class="row mt-4">
                <label class="required col-lg-5">{{ modalJob }}</label>
                <div class="col-lg-7">
                  <ValidationProvider
                    ref="jobId"
                    :rules="{ required: true }"
                    v-slot="{ errors }"
                  >
                    <Select
                      name="job_id"
                      :value="candidate.job_id"
                      @input="handleChangeSelect"
                      :options="
                        options && options['jobs'] ? options['jobs'] : []
                      "
                      :error="errors[0]"
                      :disabled="
                        options['jobs'] && options['jobs'].length ? false : true
                      "
                    />
                  </ValidationProvider>
                </div>
                <h6
                  v-if="!(options['jobs'] && options['jobs'].length)"
                  class="text-center mt-2 w-100"
                  style="color: red"
                >
                  No jobs found for shortlist!
                </h6>
              </CRow>
            </div>
            <CRow class="row mt-3" v-if="modalLabel">
              <label class="required col-lg-5">{{ modalLabel }}</label>
              <div class="col-lg-7">
                <ValidationProvider
                  ref="reasonId"
                  :rules="{ required: true }"
                  v-slot="{ errors }"
                >
                  <Select
                    name="reason_id"
                    :value="candidate.reason_id"
                    @input="handleChangeSelect"
                    :options="
                      options && options['reasons'] ? options['reasons'] : []
                    "
                    :error="errors[0]"
                  />
                </ValidationProvider>
              </div>
            </CRow>
            <CRow class="row mt-3" v-if="isCommentRequired">
              <label class="col-lg-5">Comments</label>
              <div class="col-lg-7">
                <ValidationProvider
                  :rules="{ required: false }"
                  v-slot="{ errors }"
                >
                  <TextInput
                    name="comments"
                    :value="candidate.comments"
                    @input="handleInput"
                    :error="errors[0]"
                  />
                </ValidationProvider>
              </div>
            </CRow>
          </form>
        </ValidationObserver>
      </div>
    </CModal>
  </div>
</template>
<script>
import Vue from "vue";
import { mapGetters, mapActions } from "vuex";
import Modal from "@/components/reusable/Modal";
import TextInput from "@/components/reusable/Fields/TextInput";
import Select from "@/components/reusable/Fields/Select";
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
extend("required", { ...required, message: "This field is required" });

export default {
  extends: Modal,
  name: "PopupModal",
  props: {
    popupModalCallBack: {},
    modalLabel: {
      type: String,
      default: "",
    },
    candidateItem: {},
    modalJob: {
      type: String,
      default: "",
    },
    modalReasonType: {},
    isCommentRequired: {
      type: Boolean,
      default: true,
    },
    size:{
      type:String,
      default:undefined
    }
  },
  components: {
    TextInput,
    Select,
  },
  data() {
    return {
      candidate: {},
      payload: {},
    };
  },
  computed: {
    ...mapGetters([
      "getCandidateJobs",
      "reasonsFilterByReasonType",
      "getReasons",
      "getAllJobsListByShortlistProcess",
    ]),
    options() {
      return {
        reasons:
          this.reasonsFilterByReasonType({
            reasonType: this.modalReasonType,
          }) || [],
        jobs: this.getAllJobsListByShortlistProcess || [],
      };
    },
  },
  created() {
    if (!this.getReasons?.length) {
      this.fetchReasons();
    }
  },
  watch: {
    isShowPopup() {
      if (this.isShowPopup === true) {
        this.candidate = {};
        this.payload = {};
      }
      this.$nextTick(() => {
        if (this.modalLabel) {
          this.$refs.reasonId.errors.clear;
          this.$refs.reasonId.reset();
        }
        if (this.modalJob) {
          this.$refs.jobId.errors.clear;
          this.$refs.jobId.reset();
        }
      });
    },
  },
  methods: {
    ...mapActions(["fetchReasons"]),
    handleInput(name, value) {
      Vue.set(this.candidate, name, value);
      Vue.set(this.payload, name, value);
    },
    handleChangeSelect(name, value) {
      Vue.set(this.candidate, name, value);
      this.payload = {
        ...this.payload,
        [name]: value ? value.id || value.code || value : null,
      };
      if (value === null) {
        this.clearFieldErrors(name);
      }
    },
    clearFieldErrors(name) {
      let hasError = {
        job_id: "job_id",
        reason_id: "reason_id",
      };
      this.$nextTick(() => {
        if (name === hasError.job_id) {
          this.$refs.jobId.errors.clear;
          this.$refs.jobId.reset();
        } else if (name === hasError.reason_id) {
          this.$refs.reasonId.errors.clear;
          this.$refs.reasonId.reset();
        }
      });
    },
    async onSubmit() {
      const isValid = await this.$refs.reasons.validate();
      if (!isValid) {
        this.showToast({
          class: "bg-danger text-white",
          message: "please fill mandatory fields!",
        });
        return;
      }
      this.popupModalCallBack("Confirm", this.payload, this.candidateItem);
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-outline-primary {
  padding: 0.375rem 0.75rem !important;
  font-size: 0.875rem !important;
}
</style>