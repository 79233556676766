var render = function render(){var _vm=this,_c=_vm._self._c;return _c('CRow',{staticClass:"d-flex justify-content-between p-2 dark-block bx-4"},[_c('CCol',{attrs:{"col":"4"}},[_c('div',{staticClass:"center"},[_c('img',{staticClass:"rounded-circle",style:(_vm.isDefaultProfile
            ? 'width: 80%; height:auto'
            : 'width: 100%; height:auto'),attrs:{"src":_vm.profilePicture,"onerror":"this.onerror=null;this.style='width:80%;border-radius:50%!important;';this.src='/img/user_default_pic.png';","alt":"profile_pic"}})]),_c('div',{staticClass:"text-center text-black"},[(_vm.item.gender)?_c('span',[_c('strong',[_vm._v(_vm._s(_vm.item.gender))])]):_vm._e(),_c('br'),(_vm.item.age)?_c('span',[_vm._v(_vm._s(_vm.item.age)+" Years")]):_vm._e(),(_vm.job)?_c('div',{staticClass:"item-info"},[_c('span',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
            content: _vm.job.job_title,
          }),expression:"{\n            content: job.job_title,\n          }"}],staticClass:"small-header",staticStyle:{"color":"red"}},[_vm._v("Job ID: "+_vm._s(_vm.job.job_id))])]):_vm._e()])]),_c('CCol',{staticClass:"pr-1 pl-0 position-relative mx-auto",staticStyle:{"word-break":"break-all"},attrs:{"col":"8"}},[_c('div',{staticClass:"header-content p-0 m-0"},[_c('h6',{staticClass:"m-0",style:(`color: ${_vm.getGenderColor(_vm.item.gender)}`)},[_vm._v(" "+_vm._s(_vm.toTitleCase(_vm.item.first_name))+" "+_vm._s(_vm.toTitleCase(_vm.item.surname))+" ")]),_c('div',[_c('span',{staticClass:"small-header text-black"},[_vm._v(" "+_vm._s(_vm.item.candidate_type)+" "),(_vm.item.main_qualification)?_c('span',[_vm._v(", "+_vm._s(_vm.item.qualification)+"("+_vm._s(_vm.item.main_qualification_year)+")")]):_vm._e()])]),_c('div',[(_vm.item.nationality)?_c('span',{staticClass:"text-black"},[_vm._v(_vm._s(_vm.item.nationality))]):_vm._e()]),_c('div',[_c('span',{staticClass:"text-black"},[_vm._v("Candidate ID : "),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.item.candidate_uid))])])]),(
          _vm.currentUserRole !== _vm.Role.systemCandidate &&
          _vm.currentUserRole !== _vm.Role.customerCandidate
        )?_c('div',{staticClass:"item-info"},[_c('span',{staticClass:"info text-black"},[_vm._v("Last Login: "+_vm._s(_vm.lastLogin))])]):_vm._e(),_c('div',{staticClass:"item-info text-black"},[_c('i',{staticClass:"far fa-envelope"}),_c('span',{staticClass:"info"},[_vm._v(_vm._s(_vm.item.email))])]),_c('div',{staticClass:"item-info text-black"},[_c('i',{staticClass:"fa fa-phone"}),_c('span',{staticClass:"info"},[_vm._v(_vm._s(_vm.item.phone.replace("^", "-")))])]),_c('div',{staticClass:"item-info text-black"},[_c('i',{staticClass:"fab fa-whatsapp"}),_c('span',{staticClass:"info"},[_vm._v(_vm._s(_vm.item.whatsapp.replace("^", "-") || "--"))])])]),_c('div',{staticClass:"position-absolute",staticStyle:{"bottom":"10px","right":"22px"}},[_c('CRow',[_c('h5',[_c('span',{staticClass:"item-info",attrs:{"item.location":""}})]),(_vm.item.retired_clinician === 'Yes')?_c('span',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:('Retired'),expression:"'Retired'"}],staticClass:"step bg-primary"},[_vm._v("R")]):_vm._e(),(_vm.item.volunteer === 'Yes')?_c('span',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:('Volunteer'),expression:"'Volunteer'"}],staticClass:"step bg-primary"},[_vm._v("V")]):_vm._e()])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }