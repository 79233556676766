<template>
  <div>
    <div v-if="label" class="mb-2">{{ label }}</div>

    <date-picker
      :name="name"
      :style="{ position: 'relative', width: '100%' }"
      :popup-style="{ position: 'absolute', 'z-index': 9999 }"
      :value="selectedValueCustom"
      valueType="format"
      @input="input"
      :input-class="{ 'm-0': true, 'form-control': true, 'is-danger': error }"
      :placeholder="placeholder"
      :multiple="multiple"
      :clearable="clearable"
      :disabled="disabled"
    />
    <small class="has-error" v-if="error">{{ error }}</small>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

export default {
  name: "filter-datePicker",
  components: { DatePicker },
  props: {
    name: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: "",
    },
    value: {
      type: [Object, String, Number, Array, Date],
      default: () => "",
    },
    label: {
      type: String,
      default: undefined,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    error: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    selectedValueCustom() {
      if (!this.value || !this.value.length) {
        return "";
      }
      let val = this.value.toString().replace(/]|\[/g, "");
      return val;
    },
  },
  methods: {
    input(value) {
      this.$emit("change", this.name, value);
      this.$emit("input", this.name, value);
    },
  },
};
</script>
<style lang="scss">
.datepicker {
  z-index: 999999 !important;
  position: relative;
}
.datepicker {
  position: relative;
  z-index: 999999 !important;
}
.has-error {
  color: $red;
}
.is-danger {
  .vs__dropdown-toggle {
    border-color: $red;
    box-shadow: none;
    outline: none;
  }
}
</style>
