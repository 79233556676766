<template>
  <CRow class="d-flex justify-content-between p-2 dark-block bx-4">
    <CCol col="4">
      <div class="center">
        <img
          :src="profilePicture"
          class="rounded-circle"
          :style="
            isDefaultProfile
              ? 'width: 80%; height:auto'
              : 'width: 100%; height:auto'
          "
          onerror="this.onerror=null;this.style='width:80%;border-radius:50%!important;';this.src='/img/user_default_pic.png';"
          alt="profile_pic"
        />
      </div>
      <div class="text-center text-black">
        <span v-if="item.gender"
          ><strong>{{ item.gender }}</strong></span
        ><br />
        <span v-if="item.age">{{ item.age }} Years</span>
        <div class="item-info" v-if="job">
          <span
            class="small-header"
            style="color: red"
            v-c-tooltip="{
              content: job.job_title,
            }"
            >Job ID: {{ job.job_id }}</span
          >
        </div>
      </div>
    </CCol>
    <CCol
      col="8"
      class="pr-1 pl-0 position-relative mx-auto"
      style="word-break: break-all"
    >
      <div class="header-content p-0 m-0">
        <h6 class="m-0" :style="`color: ${getGenderColor(item.gender)}`">
          {{ toTitleCase(item.first_name) }} {{ toTitleCase(item.surname) }}
        </h6>
        <div>
          <span class="small-header text-black">
            {{ item.candidate_type }}
            <span v-if="item.main_qualification"
              >, {{ item.qualification }}({{
                item.main_qualification_year
              }})</span
            >
          </span>
        </div>
        <div>
            <span class="text-black" v-if="item.nationality">{{
                item.nationality
            }}</span> 
        </div>
        <div>
          <span class="text-black"
            >Candidate ID :
            <span class="font-weight-bold">{{ item.candidate_uid }}</span></span
          >
        </div>
        <div
          class="item-info"
          v-if="
            currentUserRole !== Role.systemCandidate &&
            currentUserRole !== Role.customerCandidate
          "
        >
          <span class="info text-black">Last Login: {{ lastLogin }}</span>
        </div>
        <div class="item-info text-black">
          <i class="far fa-envelope"></i>
          <span class="info">{{
            item.email
          }}</span>
        </div>
        <div class="item-info text-black">
          <i class="fa fa-phone"></i>
          <span class="info">{{
            item.phone.replace("^", "-")
          }}</span>
        </div>
        <div class="item-info text-black">
          <i class="fab fa-whatsapp"></i>
          <span class="info">{{
            item.whatsapp.replace("^", "-") || "--"
          }}</span>
        </div>
      </div>

      <div class="position-absolute" style="bottom: 10px; right: 22px">
        <CRow>
          <h5><span class="item-info" item.location></span></h5>
          <span
            class="step bg-primary"
            v-if="item.retired_clinician === 'Yes'"
            v-c-tooltip="'Retired'"
            >R</span
          >
          <span
            class="step bg-primary"
            v-if="item.volunteer === 'Yes'"
            v-c-tooltip="'Volunteer'"
            >V</span
          >
        </CRow>
      </div>
    </CCol>
  </CRow>
</template>

<script>
import moment from "moment";
import { mapGetters, mapActions } from "vuex";
import { Role, getScope, appendAccessToken } from "@/helpers/helper";
import { BASE_URL } from '@/service_urls'

export default {
  name: "CandidateInfo",
  props: {
    item: {
      type: Object,
      default: {},
    },
    job: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      currentUserRole: getScope(),
      Role,
    };
  },
  computed: {
    ...mapGetters([
      "getStatusIcon",
      "getStatusColor",
      "getGenderColor",
      "getGenderImage",
      "getCustomerId",
    ]),
    lastLogin() {
      return this.item.last_date_of_login
        ? moment(this.item.last_date_of_login).format("DD-MMM-YYYY")
        : "--";
    },
    profilePicture() {
      let _base_domain = `${window.location.origin}/api/v1`;
      return this.item?.profile_picture != "/img/user_default_pic.png"
        ? appendAccessToken(`${_base_domain}/customer/${this.getCustomerId}/file/${this.item?.profile_picture}`)
        : "/img/user_default_pic.png";
    },
    isDefaultProfile() {
      return this.profilePicture == "/img/user_default_pic.png" || false;
    },
  },
  methods: {
    toTitleCase(str) {
      return str.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.doh-image {
  border-radius: 0% !important;
}
.cust-progress {
  height: 8px !important;
}
.item-info {
  .info-small-text {
    display: flex;
    align-items: center;
    font-size: 12px;
    .info {
      margin-left: 5px;
    }
  }
}
.circle-border {
  border-radius: 50%;
  padding: 2px;
  margin: 2px;
}
span.step {
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: $white;
  display: inline-block;
  font-family: Poppins, sans-sarif !important;
  font-weight: 300;
  line-height: 1.6em;
  margin-right: 5px;
  text-align: center;
  width: 1.6em;
  opacity: 0.9;
}
.bx-4 {
  height: 200px;
}
.center {
  display: flex;
  justify-content: center;
}
</style>