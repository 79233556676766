<template>
  <CRow>
    <LoadingOverlay v-if="isFetchingFilter" />
    <CCol md="3">
      <CandidateCount
        :totalCount="getTotalCount"
        :filterCount="getCandidateCount"
      />
      <CandidateFilters
        :key="componentKey"
        v-if="!isFetchingFilter"
        :callback="filterCallback"
        :filterData="getCandidateFilterData"
        :basicFilterlength="basicFilterlength"
      />
    </CCol>
    <CCol md="9" class="mb-5">
      <CRow>
        <CCol col="12">
          <div class="d-flex mt-3 mb-3">
            <CCol class="px-0">
              <TextInput
                @onEnter="handleSearchSubmit"
                name="searchTerm"
                @input="handleSearchInput"
                :value="searchTerm"
              />
            </CCol>
            <CButtonClose
              v-if="searchTerm"
              name="searchClose"
              @click="handleClear"
              buttonClasses="btn-primary mx-1 c-close"
            />
            <button
              name="searchButton"
              @click="handleSearchSubmit"
              class="btn btn-primary mx-1"
            >
              Search
            </button>
          </div>
        </CCol>
        <CCol v-for="(item, index) in getCandidates" :key="index" sm="6" md="4">
          <div v-if="isFetchingCandidates" class="skeleton-card"></div>
          <CandidateList v-else :item="item" />
        </CCol>
        <CCol
          class="d-flex justify-content-center"
          v-if="
            !isFetchingFilter && !isFetchingCandidates && !getCandidates.length
          "
        >
          <h1>No Data found!</h1>
        </CCol>
      </CRow>
      <CRow
        v-if="!noMoreDataFromCandidateList"
        class="d-flex justify-content-around align-items-center"
        style="height: 200px"
      >
        <div class="has-loading-overlay">
          <LoadingOverlay v-if="isFetchingCandidates" />
        </div>
      </CRow>
    </CCol>
    <PopupModal
      :modalTitle="popupModal.modalTitle"
      :modalColor="popupModal.modalColor"
      :modalContent="popupModal.modalContent"
      :modalLabel="popupModal.modalLabel"
      :isShowPopup="popupModal.isShowPopup"
      :popupModalCallBack="popupModalCallBack"
      :candidateItem="popupModal.candidateItem"
      :modalJob="popupModal.modalJob"
      :modalReasonType="popupModal.modalReasonType"
    />
  </CRow>
</template>
<script>
import CandidateList from "@/containers/CandidateList/CandidateList";
import CandidateFilters from "@/components/reusable/Filter/index";
import CandidateCount from "@/components/Count";
import TextInput from "@/components/reusable/Fields/TextInput";
import { mapGetters, mapActions } from "vuex";
import router from "@/router";
import { Role } from "@/helpers/helper";
import candidateFilter from "@/schema/candidateFilter";
import PopupModal from "@/components/reusable/PopupModal";

export default {
  name: "CandidateMain",
  data() {
    return {
      componentKey: 0,
      searchTerm: "",
      popupModal: {
        modalColor: "",
        modalTitle: "",
        modalContent: "",
        modalLabel: "Select Reason Code",
        isShowPopup: false,
        candidateItem: {},
        modalJob: "",
        modalReasonType: ""
      }
    };
  },
  components: {
    CandidateList,
    CandidateFilters,
    CandidateCount,
    TextInput,
    PopupModal
  },
  watch: {
    getCandidateFilterData(val) {
      this.componentKey += 1;
    },
    getImpersonationStatus() {
      let { query } = this.$route;
      this.initalStateCandidateListFilter();
      this.initCandidateListFilters(query);
    }
  },
  created() {
    let { query } = this.$route;
    let self = this;

    if (this.$route.query.searchTerm) {
      this.searchTerm = this.$route.query.searchTerm;
      this.handleSearchSubmit();
    } else {
      this.initCandidateListFilters(query);
    }
  },
  mounted() {
    const ele = document.getElementById("c-body-app");
    if (ele) {
      ele.addEventListener("scroll", this.onBodyScroll);
    }
    this.resetBackCount();
  },
  beforeDestroy() {
    const ele = document.getElementById("c-body-app");
    if (ele) {
      ele.removeEventListener("scroll", this.onBodyScroll);
    }
    this.initalStateCandidateListFilter();
  },
  computed: {
    ...mapGetters([
      "getCandidates",
      "getCandidateFilterData",
      "isFetchingCandidates",
      "noMoreDataFromCandidateList",
      "isFetchingFilter",
      "getCandidateCount",
      "getTotalCount",
      "candidateListFilter",
      "getImpersonationStatus",
      "getRoleScopes"
    ]),
    basicFilterlength() {
      if (this.currentUserRole === Role.systemAdmin) {
        return 9;
      } else {
        return 7;
      }
    },
    currentUserRole() {
      return this.getRoleScopes.length ? this.getRoleScopes[0] : "";
    }
  },
  methods: {
    ...mapActions([
      "initCandidateListFilters",
      "onChangeCandidateListFilterWithRouterChange",
      "fetchMoreCandidateList",
      "initalStateCandidateListFilter",
      "candidateListSearch",
      "candidateListPagination",
      "resetBackCount",
      "createInterviewlist",
      "createRecruitList",
      "createshortlist",
      "releaseCandidate"
    ]),
    handleSearchInput(name, value) {
      this.searchTerm = value;
    },
    handleClear() {
      this.searchTerm = undefined;
      this.handleSearchSubmit();
    },
    handleSearchSubmit() {
      this.$router.push({
        path: this.$router.currentRoute?.fullPath,
        query: { searchTerm: this.searchTerm }
      });
      if (this.$route.query?.searchTerm) {
        let encodedSearchTerm = this.$route.query?.searchTerm?.replace(
          "+",
          "%2B"
        );
        encodedSearchTerm = encodedSearchTerm.replace("-", "^");
        let payload = {
          full_name__iexact: encodedSearchTerm,
          email__iexact: encodedSearchTerm,
          phone__iexact: encodedSearchTerm,
          use_or: true
        };
        this.candidateListPagination(payload);
      } else {
        let payload = this.$route.query;
        let result = {};
        delete payload.searchTerm;
        for (let key in payload) {
          if (payload?.hasOwnProperty(key)) {
            let queryIdsResult;
            if (Array.isArray(payload[key])) {
              queryIdsResult = payload[key];
            } else if (payload[key]) {
              let arrayToStr = payload[key].replace(/]|\[/g, "");
              queryIdsResult = [...arrayToStr.split(/,\s*/)];
            }
            result[candidateFilter[key]["queryKey"]] = queryIdsResult.join(",");
          }
        }
        this.candidateListPagination(result);
        router.push(this.$route.fullPath);
      }
    },
    filterCallback(filters, name) {
      this.onChangeCandidateListFilterWithRouterChange({
        payload: filters,
        changed_name: name
      });
    },
    onBodyScroll(e) {
      if (this.isFetchingCandidates || this.noMoreDataFromCandidateList) return;
      if (
        e.target.clientHeight + e.target.scrollTop >=
        e.target.scrollHeight - 200
      ) {
        if (this.searchTerm || this.candidateListFilter.full_name__iexact) {
          this.candidateListFilter.isSearch = true;
        }
        this.fetchMoreCandidateList();
      }
    },
    popupModalCallBack(action, payload, candidateItem) {
      if (action === "Confirm") {
        let shortlistPayload = {
          candidate_id: candidateItem.candidate_id,
          reason_id: payload.reason_id,
          comments: payload.comments
        };
        const { modalTitle } = this.popupModal;
        const {
          shortlistStatus: { status: shortlistStatus },
          interviewStatus: { status: interviewStatus },
          recruitStatus: { status: recruitStatus },
          releaseStatus: { status: releaseStatus }
        } = candidateItem;

        if (modalTitle === shortlistStatus) {
          shortlistPayload.job_id = payload.job_id;
          let { query } = this.$route;
          let countPayload = {};
          delete query.searchTerm;
          for (let key in query) {
            if (query?.hasOwnProperty(key)) {
              let queryIdsResult;
              if (Array.isArray(query[key])) {
                queryIdsResult = query[key];
              } else if (query[key]) {
                let arrayToStr = query[key].replace(/]|\[/g, "");
                queryIdsResult = [...arrayToStr.split(/,\s*/)];
              }
              countPayload[
                candidateFilter[key]["queryKey"]
              ] = queryIdsResult.join(",");
            }
          }
          this.createshortlist({ shortlistPayload, countPayload });
        } else if (modalTitle === interviewStatus) {
          shortlistPayload.candidate_status_id =
            candidateItem.doh_candidate_status.candidate_status_id;
          shortlistPayload.status_id = candidateItem.interviewStatus.status_id;
          this.createInterviewlist(shortlistPayload);
        } else if (modalTitle === recruitStatus) {
          shortlistPayload.candidate_status_id =
            candidateItem.doh_candidate_status.candidate_status_id;
          shortlistPayload.status_id = candidateItem.recruitStatus.status_id;
          this.createRecruitList(shortlistPayload);
        } else if (modalTitle === releaseStatus) {
          shortlistPayload.candidate_status_id =
            candidateItem.doh_candidate_status.candidate_status_id;
          shortlistPayload.status_id = candidateItem.releaseStatus.status_id;
          if (
            candidateItem.doh_candidate_status.status_id ===
            candidateItem.shortlistStatus.status_id
          ) {
            shortlistPayload.releasefrom =
              candidateItem.shortlistStatus.status_id;
          } else if (
            candidateItem.doh_candidate_status.status_id ===
            candidateItem.interviewStatus.status_id
          ) {
            shortlistPayload.releasefrom =
              candidateItem.interviewStatus.status_id;
          } else if (
            candidateItem.doh_candidate_status.status_id ===
            candidateItem.recruitStatus.status_id
          ) {
            shortlistPayload.releasefrom =
              candidateItem.recruitStatus.status_id;
          }
          this.releaseCandidate(shortlistPayload);
        }
        this.popupModal.isShowPopup = false;
      } else if (action === "Cancel" || action === false) {
        this.popupModal.isShowPopup = false;
      }
    }
  }
};
</script>
