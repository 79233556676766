<template>
  <div class="mt-3" :class="filterClass">
    <div class="mb-2">{{label}}</div>
    <Select
      :name="name"
      :value="selectedValue"
      @input="onInput"
      :options="options"
      :taggable="taggable"
      :multiple="multiple"
      :clearable="clearable"
      :disabled="disabled"
      :fetchOptions="handleFetchOption"
    />
  </div>
</template>

<script>
import Select from "@/components/reusable/Fields/Select";

export default {
  components: { Select },
  props: {
    name: {
      type: String,
      default: "selectBoxName"
    },
    label: {
      type: String,
      default: "selectBoxlabel"
    },
    onInput: {
      type: Function,
      default: () => {
        console.log("Error in filter select onInput");
      }
    },
    options: {
      type: Array,
      default: []
    },
    selectedValue: {
      type: Array || Object,
    },
    display: {
      type: Boolean,
      default: true
    },
    taggable: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    fetchOptions: {
      type: Function,
      default: () => { console.log("fetchOptions");}
    }
  },
  computed: {
    filterClass(){
      return {'hide-filter': !this.display}
    }
  },
  methods:{
    handleFetchOption(search, loading){
      this.fetchOptions(search, loading, this.name)
    }
  }
};
</script>
