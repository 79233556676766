<template>
  <div>
    <button
      type="button"
      class="d-block d-md-none btn btn-primary btn-block mb-3"
      @click="myFilter"
    >Filters &dtrif;</button>
    <div
      id="filters"
      class="pr-4 sidebar-filter collapse d-md-block"
      v-bind:class="{'show': isActive}"
    >
      <div>
        <!-- <h6 v-if="basicFilterlength>1">Basic Search</h6> -->
        <component
          :is="data.component"
          v-for="(data, index) in basicSearchData"
          :key="index"
          :name="data.name"
          :label="data.label"
          :selectedValue="data.selected"
          :onInput="onInput"
          :options="data.options"
          :taggable="data.taggable"
          :multiple="data.multiple"
          :clearable="data.clearable"
          :rangeValue="data.selected[0]"
          :min="data.min"
          :max="data.max"
          :display="data.display"
          :disabled="data.disabled"
        />
      </div>
      <div v-if="basicFilterlength>1" class="mb-4 pb-4">
        <div class="border-top mt-4 pt-3 pb-1">
          <a class="btn btn-lg toggle px-1" @click="toggleAdvanced()">
            <span name="advancedSearch" class="mr-2">Advanced Search</span>
            <CIcon v-if="!isAdvancedFilterVisible" size="lg" name="cil-plus" />
            <CIcon v-else size="lg" name="cil-minus" />
          </a>
        </div>
        <div v-if="isAdvancedFilterVisible">
          <component
            :is="data.component"
            v-for="(data, index) in advancedSearchData"
            :key="index"
            :name="data.name"
            :label="data.label"
            :selectedValue="data.selected"
            :onInput="onInput"
            :options="data.options"
            :taggable="data.taggable"
            :multiple="data.multiple"
            :clearable="data.clearable"
            :rangeValue="data.selected[0]"
            :min="data.min"
            :max="data.max"
            :display="data.display"
          />
        </div>
      </div>
    </div>
  </div>
</template> 

<script>
import Vue from "vue";
import filterSelect from "@/components/reusable/Filter/components/filterSelect";
import Range from "@/components/reusable/Fields/Range";
import datePicker from "../../../components/reusable/Fields/FilterDatePicker";

Vue.component("filterSelect", filterSelect);
Vue.component("filterRange", Range);
Vue.component("filterDatePicker", datePicker);

import { deepClone, queryAssignSelectedData } from "@/helpers/helper.js";

export default {
  props: ["callback", "filterData", "basicFilterlength"],
  data() {
    return {
      data: {},
      isActive: false,
      isAdvancedFilterVisible: false
    };
  },
  computed: {
    completeSearchData() {
      return Object.keys(this.data)
        .filter(i => this.data[i].display !== false)
        .map(i => this.data[i]);
    },
    basicSearchData() {
      if (this.basicFilterlength) {
        let selectedFilter = this.completeSearchData.filter(
          val => val.name == "speciality" && val.selected.length > 0
        );
        let candidateTypeAllFilter = this.completeSearchData.filter(
          val =>
            val.name === "candidateType" &&
            val.selected.find(e => e.label === "All")
        );
        let disableFilterList = [
          "speciality",
          "recruitingCandidateLevel"
        ];
        this.completeSearchData.forEach(keyEle => {
          if (disableFilterList.includes(keyEle.name)) {
            keyEle.disabled = candidateTypeAllFilter.length > 0;
            return;
          }
        });
          this.completeSearchData.forEach(keyEle => {
            if (keyEle.name === "subSpeciality") {
              keyEle.disabled = !selectedFilter.length > 0;
              return;
            }
          });

        return this.completeSearchData.slice(0, this.basicFilterlength);
      }
      return this.completeSearchData;
    },
    advancedSearchData() {
      return this.completeSearchData.slice(
        this.basicFilterlength,
        this.basicFilterlength.length
      );
    }
  },
  created() {
    let { query } = this.$route;
    this.data = queryAssignSelectedData(this.filterData, query);
    const queryKeys = Object.keys(query);
    if (this.hasAdvanceData(queryKeys) && this.basicFilterlength > 1) {
      this.isAdvancedFilterVisible = true;
    } else {
      this.isAdvancedFilterVisible = false;
    }
    if(this.basicFilterlength === 9){
      this.data["active"]["display"] = true;
      this.data["deleted"]["display"] = true;
    }
    else{
      this.data["active"]["display"] = false;
      this.data["deleted"]["display"] = false;
    }
  },
  watch: {
    filterData(val) {
      this.data = val;
    }
  },
  methods: {
    hasAdvanceData(queryKeys) {
      let advanceData = this.getAdvancedSearchDataNames();
      let result = false;
      queryKeys.forEach(keyEle => {
        if (advanceData.includes(keyEle)) {
          result = true;
          return;
        }
      });
      return result;
    },
    getAdvancedSearchDataNames() {
      if (this.basicFilterlength && this.advancedSearchData) {
        return this.advancedSearchData.map(item => item.name);
      }
      return [];
    },
    toggleAdvanced() {
      this.isAdvancedFilterVisible = !this.isAdvancedFilterVisible;
    },
    clearAll() {
      let self = this;
      let keys = Object.keys(this.data);
      let result = keys.reduce((acc, key) => {
        if (self.data[key]["selected"].length) {
          acc[key] = { ...self.data[key], selected: [] };
        } else {
          acc[key] = self.data[key];
        }
        return acc;
      }, {});
      this.data = result;
      this.callback({});
    },
    onInput(name, value) {
      let self = this;
      if (name === "ageLimit" || !this.data[name]["multiple"]) {
        value = value ? [value] : [];
      }
      this.data[name]["selected"] = value;
      if (name === "speciality" && this.data[name]["selected"].length > 0) {
        this.data["subSpeciality"]["disabled"] = false;
      } else if (this.data["speciality"]["selected"].length == 0) {
        this.data["subSpeciality"]["disabled"] = true;
      }
      let result = Object.keys(this.data).reduce((acc, keys) => {
        if (self.data[keys]["selected"].length) {
          acc[keys] = self.data[keys]["selected"].map(val => val.code);
        }
        return acc;
      }, {});
      if (name === "ageLimit") {
        delete result["ageLimit"];
        result["ageLimit"] = JSON.parse(JSON.stringify(value));
      }
      self.callback(result, name);
    },
    myFilter: function() {
      this.isActive = !this.isActive;
    }
  }
};
</script>