<template>
  <div class="mt-3">
    <p v-if="label">{{label}}</p>
    <v-slider :name="name" :min="20" :max="max" :value="rangeValue || 20" @change="onChange"></v-slider>
  </div>
</template>

<script>
const debounce = require("lodash.debounce");
export default {
  props: ["rangeValue", "min", "max", "onInput", "name", "label"],
  methods: {
    onChange: debounce(function(value) {
      this.onInput(this.name, value);
    }, 500)
  }
};
</script>
