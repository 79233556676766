<template>
  <div class="d-flex color-primary">
    <!-- <div class="candidate-count mb-2 pr-4">
    <div class="mr-2" name="total-count">Total Candidates <span>{{totalCount && totalCount.toLocaleString()}}</span></div>
    <div class="ml-2" name="search-count">Search Result<span>{{filterCount && filterCount.toLocaleString()}}</span></div>
    </div>-->
    <h5 class="count mr-3">
      Total {{ type }}:
      <span class="ft-bold">{{
        totalCount && totalCount.toLocaleString()
      }}</span>
    </h5>
    <h5 class="count">
      Search Results:
      <span class="ft-bold">{{
        filterCount && filterCount.toLocaleString()
      }}</span>
    </h5>
    <button
      type="button"
      class="btn btn-link py-0 d-flex fw-500"
      v-if="enableDowload"
      @click="$emit('downloadResult')"
      v-c-tooltip="'Download Results'"
    >
      <span class="mr-2"><i class="fa-sharp fa-solid fa-download"></i></span
      >Download Results
    </button>
  </div>
</template>

<script>
export default {
  props: {
    filterCount: {
      type: Number,
    },
    totalCount: {
      type: Number,
    },
    isJob: {
      type: Boolean,
      default: false,
    },
    enableDowload: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    type() {
      return this.isJob ? "Jobs" : "Candidates";
    },
  },
};
</script>
<style lang="scss" scoped>
.color-primary {
  color: #085d66e6;
}
.candidate-count {
  display: flex;
  width: 100%;
  div {
    background-color: #085d66e6;
    color: #ffffff;
    padding: 3px;
    text-align: center;
    width: 50%;
    span {
      display: block;
      padding: 3px;
      background-color: #ffffff;
      font-weight: 700;
      color: $color-black;
    }
  }
}
.fw-500{
  font-weight:500;
}
</style>
